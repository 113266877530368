import React from "react";
import { pass } from "../../../schemas/pass";
import { useFormik } from "formik";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
const initialValues = {
  cpass: "",
  password: "",
  cnfPass: "",
  
};
export default function ChangePassword() {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    values,
    
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: pass,

    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("cpass", values.cpass);
      formData.append("password", values.password);
      formData.append("cnfPass", values.cnfPass);
     
      fetch("http://api.dealsmart.ae/api/change_password/"+ id, {
        method: "POST",
        body: formData,
      }).then((response) => {
        if (response.status === 200 || response.status === 201) {
          swal({
            title: "success!",
            text: "Succrssfully Changed!",
            icon: "success",
            width: '247px',
            height: '314px',
            button: "OK",
          });
          navigate("/User");
        } else {
          swal({
            title: "Fails!",
            text: "Fails to Change!",
            icon: "error",
            width: '247px',
            height: '314px',
            button: "OK",
          });
          navigate("/ChangePassword/"+id);
        }
      });
    },
  });

  return (
    <>
      <section className="intro">
        <div className=" mb-5">
          <section className="h-100 ">
            <form onSubmit={handleSubmit}>
              <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col">
                    <div className="card card-registration my-4">
                      <div className="row g-0">
                      
                        <div className="col-xl-12 ">
                          <div className="card-body p-md-2 text-black">
                            <div id="grad">
                             
                            </div>

                            <div className="row">
                              <div className="col-md-12 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="text"
                              
                                    className="form-control form-control-lg f"
                                    name="cpass"
                                    value={values.cpass}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Current Password"
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.cpass && touched.cpass ? (
                                      <p className="v">{errors.cpass}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                             
                            </div>
                            <div className="row">
                              
                              <div className="col-md-12 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="text"
                                    
                                    className="form-control form-control-lg f"
                                    name="password"
                                    placeholder="New Password"
                         
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label
                                    className="form-label "
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.password && touched.password ? (
                                      <p className="v">{errors.password}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              
                              <div className="col-md-12 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="password"
                             
                                    className="form-control form-control-lg f"
                                    name="cnfPass"
                                    placeholder="Confirm Password"
                                    icon="error"
                                    value={values.cnfPass}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label
                                    className="form-label "
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.cnfPass && touched.cnfPass ? (
                                      <p className="v">{errors.cnfPass}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                            </div>

                            <button type="submit" className="button mb-5" id="but">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </section>
    </>
  );
}
