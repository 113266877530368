import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';

export default function Logout() {
    const navigate = useNavigate();
    // localStorage.clear()
    localStorage.removeItem("user-info")

    useEffect(() => {
        if (!(localStorage.getItem("user-info"))) {
          navigate("/");
        }
      }, [navigate]);
   
  return (
    <div>
      
    </div>
  )
}
