
import React, { useEffect, useState} from 'react';
import HeadTop from "../SideBar/HeadTop";
import { AgGridReact } from 'ag-grid-react';
import SideBar from '../SideBar/SideBar'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import http from '../../../http'
import { Link } from 'react-router-dom';
import { RiPencilFill } from "react-icons/ri";
import Image from "react-bootstrap/Image";
import Stack from "react-bootstrap/Stack";
import { ImBin } from "react-icons/im";


export default function User() {
  const [tableData, setTableData] = useState(null);

  const ImageRenderer = (props) => {
    return (
      <Stack direction="horizontal" className="h-100">
        <Image
          rounded
          src={"https://api.dealsmart.ae/Products/" + props.getValue()}
          className="agImg"
        />
      </Stack>
    );
  };

  useEffect(() => {
    fetchAllData();
  }, []);
  const fetchAllData = () => {
    http.get("/userList").then((res) => {
      setTableData(res.data);
    });
  };

  

  const columnDefs = [
    {
      headerName: "",
      field: "img",
      cellRenderer: ImageRenderer,
    },
    {
      headerName: "Name",
      field: "name",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Email",
      field: "email",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "CNIC",
      field: "cnic",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Phone Number",
      field: "pno",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Date",
      field: "date",
      cellStyle: { fontSize: "11px" },
    },

    {
      headerName: "Action",
      field: "id",
      cellRendererFramework: (params) => (
        <div>
          <Link to="/User">
            <ImBin onClick={() => deleteData(params.value)} />
          </Link>
          <Link to={{ pathname: "/userEdit/" + params.value }}>
            <RiPencilFill />
          </Link>
        </div>
      ),
    },
  ];
  const deleteData = (id) => {
    http.delete("/userDelete/" + id).then((res) => {
      fetchAllData();
    });
  };

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
  };

  return (
    
<>
<div style={{display:"flex"}}>
  <div>  <SideBar /></div>
  <div style={{display:"flex", flexDirection:'column'}}>
    <div>    <HeadTop txt="New Deals Alert - " /></div>
    <div className="mt-2 mb-2">
            <Link to="/SignUp">
              <button type="submit" className="button">
                ADD NEW
              </button>
            </Link>
          </div>
    <div className="ag-theme-alpine gridSize" id="gridSize-res">
        <AgGridReact
            rowData={tableData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
             pagination={true}
              paginationPageSize={7}
              rowHeight={50}
              
            >
                
        </AgGridReact>
    </div>
    </div>
  </div>
{/* </div> */}

</> 
  );
}
