import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
import http from "../../../http";
const initialValues = {
  name: "",
  total:"",
  offf:"",
  afterOff:"",
  stock:"",
  smallDesc:"",
  Des:'',
  cID:"",
  rank:"",
  img: "",
};
export default function EditProduct() {
  
  const [data, setData] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const fetchAllData = () => {
      http.get("/showByIdProduct/" + id).then((res) => {
        setData(res.data);
      });
    };
    fetchAllData();
  }, [id]);
  const navigate = useNavigate();
  const {
    values,
    setFieldValue,

    handleBlur,
    handleChange,
   
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,

    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("total", values.total);
      formData.append("offf", values.offf);
      formData.append("afterOff", values.afterOff);
      formData.append("stock", values.stock);
      formData.append("cID", values.cID);
      formData.append("rank", values.rank);
      formData.append("smallDesc", values.smallDesc);
      formData.append("Des", values.Des);
      formData.append("img", values.img);


      fetch("https://api.dealsmart.ae/api/productEdit/" + id + "?_method=PUT", {
        method: "POST",
        body: formData,
      }).then((response) => {
        if (response.status === 200 || response.status === 201) {
          swal({
            title: "success!",
            text: "Succrssfully Edit!",
            icon: "success",
            width: '247px',
            height: '314px',
            button: "OK",
          });
          navigate("/product");
        } else {
          swal({
            title: "Fails!",
            text: "Fails to Edit!",
            icon: "error",
            width: '247px',
            height: '314px',
            button: "OK",
          });
          navigate("/productEdit/" + id);
        }
      });
    },
  });

  return (
    <>
      <section className="intro">
        <div className=" mb-5">
          <section className="h-100 ">
            <form onSubmit={handleSubmit}>
              <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col">
                    <div className="card card-registration my-4">
                      <div className="row g-0">
                        
                        <div className="col-xl-12 ">
                          <div className="card-body p-md-2 text-black">
                            <div id="grad">
                              <h3
                                className="flex logo "
                                id="grad"
                                style={{ justifyContent: "center" }}
                              >
                                Create Account
                              </h3>
                            </div>

                            <div className="row">
                              <div className="col-md-6 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="text"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="name"
                                    defaultValue={data.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Full Name"
                                  />
                                 <span className="label label-default" style={{fontSize:"11px", marginLeft:"10px"}}>Name</span>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                              <div className="form-outline">
                                  <input
                                    type="number"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="total"
                                    defaultValue={data.total}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Orignal price"
                                  />
                              <span className="label label-default" style={{fontSize:"11px", marginLeft:"10px"}}>Orignal Price</span>
                                </div>
                              </div>
                            </div>
                            

                            <div className="row mt-1">
                              <div className="col-md-6 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="number"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="offf"
                                    defaultValue={data.offf}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter off"
                                  />
                                 <span className="label label-default" style={{fontSize:"11px", marginLeft:"10px"}}>% OFF </span>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                              <div className="form-outline">
                                  <input
                                    type="number"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="afterOff"
                                    defaultValue={data.afterOff}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder={(values.total - (values.offf *(values.total/100)))}
                                  />
                                <span className="label label-default" style={{fontSize:"11px", marginLeft:"10px"}}>After off Price</span>
                                </div>
                              </div>
                            </div>


                            <div className="row mt-1">
                              <div className="col-md-6 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="number"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="stock"
                                    defaultValue={data.stock}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Products in Stock"
                                  />
                                 <span className="label label-default" style={{fontSize:"11px", marginLeft:"10px"}}>Products in Stock</span>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                              <div className="form-outline">
                                  <select
                                    type="number"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="cID"
                                    defaultValue={data.cID}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    
                                  >
                                    <span className="label label-default" style={{fontSize:"11px", marginLeft:"10px"}}>Product Category</span>

                                    <option value="1" style={{'fontSize':"11px"}}>Watches</option>
                                    <option value="2" style={{'fontSize':"11px"}}>Cosmetics</option>
                                    <option value="3" style={{'fontSize':"11px"}}>Hair</option>
                                    <option value="4" style={{'fontSize':"11px"}}>Kitchen</option>
                                    <option value="5" style={{'fontSize':"11px"}}>Mobile</option>
                                    <option value="6" style={{'fontSize':"11px"}}>Car</option>
                                    <option value="7" style={{'fontSize':"11px"}}>Electronics</option>
                                    <option value="8" style={{'fontSize':"11px"}}>Other</option>
                                  </select>
                                 
                                </div>
                              </div>
                            </div>
                          
                            <div className="row mt-2">
                            <div className="col-md-6 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="number"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="rank"
                                    defaultValue={data.rank}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Ranking 1-3"
                                  />
                                 <span className="label label-default" style={{fontSize:"11px", marginLeft:"10px"}}>Ranking </span>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                              
                           
                                <div className="form-outline">
                                  <input
                                    type="file"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="img"
                                    accept=".jpg,.jpeg"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "img",
                                        event.target.files[0]
                                      );
                                    }}
                                    onBlur={handleBlur}
                                  />
                                 <span className="label label-default" style={{fontSize:"11px", marginLeft:"10px"}}>Product Picture</span>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                               
                              </div>
                            </div>


                            <div className="row mt-1">
                              <div className="col-md-6 mb-1">
                                <div className="form-outline">
                                  <textarea
                                    type="number"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="smallDesc"
                                    defaultValue={data.smallDesc}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Overview"
                                  />
                                 <span className="label label-default" style={{fontSize:"11px", marginLeft:"10px"}}>Product Overview</span>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                              <div className="form-outline">
                                  <textarea
                                    type="text"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="Des"
                                    defaultValue={data.Des}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Detailed Description"
                                  />
                                 <span className="label label-default" style={{fontSize:"11px", marginLeft:"10px"}}>Product Detailed Description</span>
                                </div>
                              </div>
                            </div>


                            <button type="submit" className="button mb-5" id="but">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </section>
    </>
  );
}
