
import React, { useEffect, useState } from 'react';
import HeadTop from "../SideBar/HeadTop";
import { AgGridReact } from 'ag-grid-react';
import SideBar from '../SideBar/SideBar'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import http from '../../../http'
import { Link } from 'react-router-dom';
import { RiPencilFill } from "react-icons/ri";
import Image from "react-bootstrap/Image";
import Stack from "react-bootstrap/Stack";
import { ImBin } from "react-icons/im";
import { SlPicture } from "react-icons/sl";
export default function Dashboard() {
    const [tableData, setTableData] = useState(null);

    const ImageRenderer = (props) => {
      return (
        <Stack direction="horizontal" className="h-100">
          <Image
            rounded
            src={"https://api.dealsmart.ae/Products/" + props.getValue()}
            className="agImg"
          />
        </Stack>
      );
    };
  
    useEffect(() => {
      fetchAllData();
    }, []);
    const fetchAllData = () => {
      http.get("/productList").then((res) => {
        setTableData(res.data);
      });
    };
  const columnDefs = [
   
    {
      headerName: "",
      field: "img",
      cellRenderer: ImageRenderer,
    },
    {
      headerName: "Product Code",
      field: "id",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Name",
      field: "name",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Orignal Price (AED)",
      field: "total",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Off %",
      field: "offf",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "After Off Price (AED)",
      field: "afterOff",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Stock",
      field: "stock",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Ranking",
      field: "rank",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Overview",
      field: "smallDesc",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Description",
      field: "Des",
      cellStyle: { fontSize: "11px" },
    },

    {
      headerName: "Category",
      field: "cID",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Date",
      field: "date",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Action",
      field: "id",
      cellRendererFramework: (params) => (
        <div>
           <Link to={{ pathname: "/searchImg/" + params.value }}>
            <SlPicture/>
          </Link>
          <Link to="/product">
            <ImBin onClick={() => deleteData(params.value)} style={{"marginLeft":"10px"}} />
          </Link>
          <Link to={{ pathname: "/productEdit/" + params.value }}>
            <RiPencilFill style={{"marginLeft":"10px"}}/>
          </Link>
        </div>
      ),
    },
  ];

  const deleteData = (id) => {
    http.delete("/productDelete/" + id).then((res) => {
      fetchAllData();
    });
  };

const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
  };
return (

<>
<div style={{display:"flex"}}>
  <div>  <SideBar /></div>
  <div style={{display:"flex", flexDirection:'column'}}>
    <div>    <HeadTop txt="New Deals Alert - " /></div>
    <div className="mt-2 mb-2">
            <Link to="/AddProduct">
              <button type="submit" className="button">
                ADD NEW
              </button>
            </Link>
          </div>
    <div className="ag-theme-alpine gridSize" id="gridSize-res">
        <AgGridReact
            rowData={tableData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
             pagination={true}
              paginationPageSize={7}
              rowHeight={50}
              
            >
                
        </AgGridReact>
    </div>
    </div>
  </div>
{/* </div> */}

</> 
);
}
