import React, { useEffect, useState } from "react";
import http from "../../../http";

import { useFormik } from "formik";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
const initialValues = {
  name: "",
  email: "",
  cnic: "",
  pno: "",
  password: "",
  img: "",
};
export default function EditUser() {

  const [data, setData] = useState([]);
  const { id } = useParams();
  useEffect(() => {
    const fetchAllData = () => {
      http.get("/showByIdUser/" + id).then((res) => {
        setData(res.data);
      });
    };
    fetchAllData();
  }, [id]);
  const navigate = useNavigate();
  const {
    setFieldValue,
    handleBlur,
    handleSubmit,
    handleChange,
  } = useFormik({
    initialValues: initialValues,

    onSubmit: (values) => {
      const formData = new FormData();

      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("cnic", values.cnic);
      formData.append("pno", values.pno);
      formData.append("img", values.img);
      fetch("https://api.dealsmart.ae/api/userEdit/" + id + "?_method=PUT", {
        method: "POST",
        body: formData,
      }).then((response) => {
        if (response.status === 200) {
          swal({
            title: "success!",
            text: "Succrssfully Edit!",
            icon: "success",
            width: '247px',
            height: '314px',
            button: "OK",
          });
          navigate("/User");
        } else {
          swal({
            title: "Fails to Edit!",
            text: "fails!",
            icon: "error",
            width: '247px',
            height: '314px',
            button: "OK",
          });
          navigate("/userEdit/" + id);
        }
      });
    },
  });
  return (
    <>
      <section className="intro">
        <div className=" mb-5">
          <section className="h-100 ">
            <form onSubmit={handleSubmit}>
              <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col">
                    <div className="card card-registration my-4">
                      <div className="row g-0">
                        <div className="col-xl-5 d-none d-xl-block">
                          <img
                            src="https://images.unsplash.com/photo-1567502401218-0aa88642ea13?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80"
                            alt=""
                            className="img-fluid"
                            style={{
                              borderTopLeftRadius: ".25rem",
                              borderBottomLeftRadius: ".25rem",
                              backgroundPosition: "center",
                              height: "22rem",
                            }}
                          />
                        </div>
                        <div className="col-xl-7 ">
                          <div className="card-body p-md-2 text-black">
                            <div id="grad">
                              <h3
                                className="flex logo "
                                id="grad"
                                style={{ justifyContent: "center" }}
                              >
                              Update
                              </h3>
                            </div>

                            <div className="row mt-5">
                              <div className="col-md-6 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="text"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="name"
                                    defaultValue={data.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Full Name"
                                  />
                                  <span className="label label-default" style={{fontSize:"11px", marginLeft:"10px"}}>Name</span>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="text"
                                    id="form3Example1n"
                                    className="form-control form-control-lg f"
                                    placeholder="Email"
                                    name="email"
                                    defaultValue={data.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <span className="label label-default" style={{fontSize:"11px", marginLeft:"10px"}}>Email</span>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-1">
                              <div className="col-md-6 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="text"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="cnic"
                                    placeholder="cnic"
                                    defaultValue={data.cnic}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <span className="label label-default" style={{fontSize:"11px", marginLeft:"10px"}}>CNIC</span>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="text"
                                    id="form3Example1n"
                                    className="form-control form-control-lg f"
                                    name="pno"
                                    placeholder="Phone Number"
                                    icon="error"
                                    defaultValue={data.pno}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <span className="label label-default" style={{fontSize:"11px", marginLeft:"10px"}}>Phone Number</span>
                                </div>
                              </div>
                            </div>
                            <div className="row mt-1">
                              <div className="col-md-12 mb-1">
                                <div className="form-outline">
                                
                                  <input
                                    type="file"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="img"
                                    accept=".jpg,.jpeg"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "img",
                                        event.target.files[0]
                                      );
                                    }}
                                    onBlur={handleBlur}
                                  />
                                </div>
                              </div>
                            </div>

                            <button type="submit" className="button mt-1" id="but">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </section>
    </>
  );
}
