import "./App.css";

import { BrowserRouter, Routes, Route } from "react-router-dom";
// user


// admin
import Dashboard from "./components/Admin/Dashboard/Dashboard";
import Product from "./components/Admin/Dashboard/Product";
import AddProduct from "./components/Admin/Dashboard/AddProduct";
import EditProduct from "./components/Admin/Dashboard/EditProduct";
import Imag from "./components/Admin/Dashboard/Imag";
import AddImg from "./components/Admin/Dashboard/AddImg";
import ZeroStock from "./components/Admin/Dashboard/ZeroStock";
import EditUser from "./components/Admin/Dashboard/EditUser";
import UserProfile from "./components/Admin/Dashboard/UserProfile";
import User from "./components/Admin/Dashboard/User";
import SignUp from "./components/Admin/Dashboard/SignUp";
import Deal from "./components/Admin/Dashboard/Deal";
import DealDetail from "./components/Admin/Dashboard/DealDetail";
import ChangePassword from "./components/Admin/Dashboard/ChangePassword";
import BillD from "./components/Admin/Dashboard/BillD";
// Auth
import Login from "./components/Admin/Auth/Login";
import AProtected from "./components/Admin/Dashboard/Aprotected";
import Logout from "./components/Admin/Dashboard/logout";


function App() {
  return (
    <BrowserRouter>
      <Routes>   
        {/* other */}
        <Route path="/" element={<Login />} />

       
        {/* Admin */}
        <Route
          path="/Dashboard"
          element={<AProtected Cmp={Dashboard} />}
        ></Route>
         <Route
          path="/product"
          element={<AProtected Cmp={Product} />}
        ></Route>
         <Route
          path="/addProduct"
          element={<AProtected Cmp={AddProduct} />}
        ></Route>
   
         <Route
          path="/productEdit/:id"
          element={<AProtected Cmp={EditProduct} />}
        ></Route>
      <Route
          path="/searchImg/:id"
          element={<AProtected Cmp={Imag} />}
        ></Route>
           <Route
          path="/addImg/:id"
          element={<AProtected Cmp={AddImg} />}
        ></Route>
        <Route
          path="/zeroP"
          element={<AProtected Cmp={ZeroStock} />}
        ></Route>
           <Route
          path="/userEdit/:id"
          element={<AProtected Cmp={EditUser} />}
        ></Route>
         <Route
          path="/userProfile"
          element={<AProtected Cmp={UserProfile} />}
        ></Route>
        
              <Route
          path="user"
          element={<AProtected Cmp={User} />}
        ></Route>
            <Route
          path="/signup"
          element={<AProtected Cmp={SignUp} />}
        ></Route>
        <Route
          path="/deal"
          element={<AProtected Cmp={Deal} />}
        ></Route>
     <Route
          path="/dealDetail/:id"
          element={<AProtected Cmp={DealDetail} />}
        ></Route>
     <Route
          path="/changePassword/:id"
          element={<AProtected Cmp={ChangePassword} />}
        ></Route>
  <Route
          path="/billd"
          element={<AProtected Cmp={BillD} />}
        ></Route>
         <Route
          path="/logout"
          element={<AProtected Cmp={Logout} />}
        ></Route>
       
      </Routes>
    </BrowserRouter>
  );
}
export default App;
