import React, { useEffect, useState } from "react";
import "./Imag.css";
import { ImBin } from "react-icons/im";
import {useParams } from "react-router-dom";
import http from "../../../http";
import { useFormik } from "formik";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
const initialValues = {
    pID:"",
    img: "",
  };
export default function Imag() {

    const [data, setData] = useState([]);
    const { id } = useParams();
    const navigate = useNavigate();
    const {
      setFieldValue,
      errors,
      handleBlur,
      touched,
      handleSubmit,
    } = useFormik({
      initialValues: initialValues, 
      onSubmit: (values) => {
        const formData = new FormData();
        formData.append("pID", id);
        formData.append("img", values.img);
  
  
        fetch("https://api.dealsmart.ae/api/addImg/"+id, {
          method: "POST",
          body: formData,
        }).then((response) => {
          if (response.status === 200 || response.status === 201) {
            swal({
              title: "success!",
              text: "Succrssfully Upload!",
              icon: "success",
              width: '247px',
              height: '314px',
              button: "OK",
            });
            navigate("/searchImg/"+id);
          } else {
            swal({
              title: "Fails!",
              text: "Fails to Upload!",
              icon: "error",
              width: '247px',
              height: '314px',
              button: "OK",
            });
            navigate("/searchImg/"+id);
          }
        });
      },
    });
    useEffect(() => {
      fetchAllData();
    }, []);
    const fetchAllData = () => {
      http.get("/searchImg/" + id).then((res) => {
        setData(res.data);
      });
    };

    const deleteData = (id) => {
        http.delete("/imagDelete/" + id).then((res) => {
          fetchAllData();
        });
      };

     
    
  const [model, setModel] = useState(false);
  const [tempimgSrc, setTempImgSrc] = useState("");
  const getImg = (imgSrc) => {
    setTempImgSrc(imgSrc);
    setModel(true);
  };
  return (
    <>
      <div className="container-fluid">
      <section >
            <form onSubmit={handleSubmit}>
              <div className="container-fluid py-5 ">
                <div className="row justify-content-center align-items-center ">
                  <div className="col">
                    <div className="card  my-4">
                      <div className="row g-0">
                        
                        <div className="col-xl-12 ">
                          <div className="card-body p-md-2 text-black">
                            <div id="grad">
                              <h3
                                className="flex logo "
                                id="grad"
                                style={{ justifyContent: "center" }}
                              >
                               ADD IMAGE
                              </h3>
                            </div>

                            <div className="row">
                            
                              <div className="col-md-12 mb-1">
                              
                           
                                <div className="form-outline">
                                  <input
                                    type="file"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="img"
                                    accept=".jpg,.jpeg"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "img",
                                        event.target.files[0]
                                      );
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.img && touched.img ? (
                                      <p className="v">{errors.img}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 ">
                               
                              </div>
                            </div>



                            <button type="submit" className="button mb-5" id="but" >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
     
          <div className="container-fluid mt-5 mb-5 ">
        <div className="row justify-content-center text-center">
        {data.map((item, index) => {
            return (
          <div className="col-6 col-lg-3 mt-3 col-md-4 col-sm-6 " >
            
            <div className="card" >
             
        
              
              <img className="card-img-top imgm  img-fluid" id="card-img" src={"https://api.dealsmart.ae/Products/" + item.img} alt="" />
             
          
             
              <div className="position-relative ">
               
               <ImBin
                     className="croxs text-primary"
                     // style={{position:"absolute"}}
                     
                     onClick={() => {
                         deleteData(item.id);
                       }}
                     
                     />
                  
               </div>
            </div>
          </div>
       
       );
      })}
        </div>
      </div> 

    </div>
      <div className="mt-4">
     
      </div>
     
    </>
  );
}
