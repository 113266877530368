
import React, { useEffect, useState} from 'react';
import HeadTop from "../SideBar/HeadTop";
import { AgGridReact } from 'ag-grid-react';
import SideBar from '../SideBar/SideBar'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import http from '../../../http'
import { Link } from 'react-router-dom';
import { RiPencilFill } from "react-icons/ri";
import { IoCheckmarkDoneCircleOutline } from "react-icons/io5";
import { useRef } from 'react'
import {useReactToPrint} from 'react-to-print';

export default function Deal() {
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: 'emp-data',
      onAfterPrint: ()=> alert('print success')
  });

  const [tableData, setTableData] = useState(null);
  useEffect(() => {
    fetchAllData();
  }, []);
  const fetchAllData = () => {
    http.get("/vc").then((res) => {
      setTableData(res.data);
    });
  };

  

  const columnDefs = [
    
    {
      headerName: "Deal ID",
      field: "id",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Name",
      field: "name",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Phone number",
      field: "pno",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Emirates",
      field: "emi",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Shipping Address",
      field: "address",
      cellStyle: { fontSize: "11px" },
    },
    
    {
      headerName: "Date",
      field: "date",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Status",
      field: "comp",
      cellStyle: { fontSize: "11px" },
    },
    {
      headerName: "Action",
      field: "id",
      cellRendererFramework: (params) => (
        <div>
          <Link to="/Deal">
            <IoCheckmarkDoneCircleOutline onClick={() => deleteData(params.value)} />
          </Link>
          <Link to={{ pathname: "/dealDetail/" + params.value }}>
            <RiPencilFill style={{"marginLeft":"10px"}}/>
          </Link>
        </div>
      ),
    },

   
  ];
  const deleteData = (id) => {
    http.put("/confi/" + id).then((res) => {
      fetchAllData();
    });
  };

  const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
  };

  return (
    
<>
<div style={{display:"flex"}}>
  <div>  <SideBar /></div>
  <div style={{display:"flex", flexDirection:'column'}}>
    <div>    <HeadTop txt="New Deals Alert - " /></div>
    <div className="mt-2 mb-2">
            <Link to="/">
              <button type="submit" className="button mx-1">
                New Deal
              </button>
              </Link>
              <button type="submit" className="button" onClick={handlePrint}>
                Print
              </button>
         
          </div>
    <div className="ag-theme-alpine gridSize" id="gridSize-res" ref={componentRef}>
        <AgGridReact
            rowData={tableData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
             pagination={true}
              paginationPageSize={7}
              rowHeight={50}
              
            >
                
        </AgGridReact>
    </div>
    </div>
  </div>
{/* </div> */}

</> 
  );
}
