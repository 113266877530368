
import React, { useEffect, useState } from 'react';
import HeadTop from "../SideBar/HeadTop";
import { AgGridReact } from 'ag-grid-react';
import SideBar from '../SideBar/SideBar'
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import http from '../../../http'
import { Link } from 'react-router-dom';
import { HiBellAlert } from "react-icons/hi2";
import './grid.css';
export default function Dashboard() {

  const [tableData, setTableData] = useState(null);

 
  useEffect(() => {
    fetchAllData();
  }, []);
  const fetchAllData = () => {
    http.get("/vUc").then((res) => {
      setTableData(res.data);

    });
  };

const columnDefs = [
  {
    headerName: "Deal ID",
    field: "id",
    cellStyle: { fontSize: "11px" },
  },
  
  {
    headerName: "Name",
    field: "name",
    cellStyle: { fontSize: "11px" },
  },
  {
    headerName: "Phone Numver",
    field: "pno",
    cellStyle: { fontSize: "11px" },
  },
  {
    headerName: "Emirates",
    field: "emi",
    cellStyle: { fontSize: "11px" },
  },
  {
    headerName: "Shipping Address",
    field: "address",
    cellStyle: { fontSize: "11px" },
  },
  {
    headerName: "Date",
    field: "date",
    cellStyle: { fontSize: "11px" },
  },

  {
    headerName: "Action",
    field: "id",
    cellRendererFramework: (params) => (
      <div>
        <Link to="/deal">
          <HiBellAlert onClick={() => deleteData(params.value)} />
        </Link>
        
      </div>
    ),
  },
];
const deleteData = (id) => {
  http.put("/dealCheck/" + id).then((res) => {
    // fetchAllData();
  });
};

const defaultColDef = {
    sortable: true,
    filter: true,
    floatingFilter: true,
  };
return (

<>
<div style={{display:"flex"}}>
  <div>  <SideBar /></div>
  <div style={{display:"flex", flexDirection:'column'}}>
    <div>    <HeadTop txt="New Deals Alert - " /></div>
    <div></div>
    <div>
    <div className="ag-theme-alpine gridSize" id="gridSize-res">
        <AgGridReact
            rowData={tableData}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
             pagination={true}
              paginationPageSize={7}
              rowHeight={50}
            >
                
        </AgGridReact>
    </div>
    </div>
  </div>
</div>
</>


    
);
  
}
