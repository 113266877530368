import React, { useEffect, useState } from 'react'
import lo from '../../../img/lo.png'
import { Link } from 'react-router-dom'
import Stack from '@mui/material/Stack';
import Badge from '@mui/material/Badge';
import MailIcon from '@mui/icons-material/Mail';
import http from '../../../http';
import { BiLogOutCircle } from 'react-icons/bi';
import { FiDownload } from 'react-icons/fi';


// import './Opts.css'
export default function HeadTop() {

  const [data, setDatas] = useState([]);

  useEffect(() => {
    const fetchAllData = () => {
      http.get("/cnt").then((res) => {
        setDatas(res.data);
        
      });
    };
    fetchAllData();
  }, []);
  return (
    <>
     <div >
  
     <div className='flex'   style={{background:"white"  , height:"2rem"}} >
 
   
     <div id="grad" >
            <h6 className="flex logo " id="grad" style={{marginLeft:"1rem"}}>
            <img src={lo} alt="" style={{width:"6rem"}}/>
              </h6>
            </div>
            <Link to="/Dashboard">
            <Stack spacing={4} direction="row" sx={{ color: 'action.active' }} className='mt-2'>
  
  <Badge color="secondary" badgeContent={data} showZero >
    <MailIcon />
  </Badge>
</Stack>
</Link>

<Link to="/billd"><FiDownload size={25} className='mt-1 mx-2'/></Link>
<Link to="/logout"><BiLogOutCircle size={25} className='mt-1'/></Link>
     <div style={{marginLeft:"20px", }}>
      
    


    </div>
    </div>
     </div>
    </>
  )
}
