import React from "react";
import { signup } from "../../../schemas/signup";
import { useFormik } from "formik";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
const initialValues = {
  name: "",
  email: "",
  cnic: "",
  pno: "",
  password: "",
  img: "",
};
export default function SignUp() {
  const navigate = useNavigate();
  const {
    values,
    setFieldValue,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: signup,

    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("email", values.email);
      formData.append("cnic", values.cnic);
      formData.append("pno", values.pno);
      formData.append("img", values.img);
      formData.append("password", values.password);

      fetch("https://api.dealsmart.ae/api/signup", {
        method: "POST",
        body: formData,
      }).then((response) => {
        if (response.status === 200 || response.status === 201) {
          swal({
            title: "success!",
            text: "Succrssfully Created!",
            icon: "success",
            button: "OK",
            width: '247px',
            height: '314px',
          });
          navigate("/User");
        } else {
          swal({
            title: "Fails!",
            text: "Fails to Create!",
            icon: "success",
            button: "OK",
            width: '247px',
            height: '314px',
          });
          navigate("/SignUp");
        }
      });
    },
  });

  return (
    <>
      <section className="intro">
        <div className=" mb-5">
          <section className="h-100 ">
            <form onSubmit={handleSubmit}>
              <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col">
                    <div className="card card-registration my-4">
                      <div className="row g-0">
                        <div className="col-xl-5 d-none d-xl-block">
                          <img
                            src="https://images.unsplash.com/photo-1567502401218-0aa88642ea13?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80"
                            alt=""
                            className="img-fluid"
                            style={{
                              borderTopLeftRadius: ".25rem",
                              borderBottomLeftRadius: ".25rem",
                              backgroundPosition: "center",
                              height: "22rem",
                            }}
                          />
                        </div>
                        <div className="col-xl-7 ">
                          <div className="card-body p-md-2 text-black">
                            <div id="grad">
                              <h3
                                className="flex logo "
                                id="grad"
                                style={{ justifyContent: "center" }}
                              >
                                Create Account
                              </h3>
                            </div>

                            <div className="row">
                              <div className="col-md-6 ">
                                <div className="form-outline">
                                  <input
                                    type="text"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Full Name"
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.name && touched.name ? (
                                      <p>{errors.name}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 ">
                                <div className="form-outline">
                                  <input
                                    type="text"
                                    id="form3Example1n"
                                    className="form-control form-control-lg f"
                                    placeholder="Email"
                                    name="email"
                                    value={values.email}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.email && touched.email ? (
                                      <p>{errors.email}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="form-outline">
                                  <input
                                    type="text"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="cnic"
                                    placeholder="cnic"
                                    value={values.cnic}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.cnic && touched.cnic ? (
                                      <p className="v">{errors.cnic}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="text"
                                    id="form3Example1n"
                                    className="form-control form-control-lg f"
                                    name="pno"
                                    placeholder="Phone Number"
                                    icon="error"
                                    value={values.pno}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label
                                    className="form-label "
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.pno && touched.pno ? (
                                      <p className="v">{errors.pno}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="file"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="img"
                                    accept=".jpg,.jpeg"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "img",
                                        event.target.files[0]
                                      );
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.img && touched.img ? (
                                      <p className="v">{errors.img}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="password"
                                    id="form3Example1n"
                                    className="form-control form-control-lg f"
                                    name="password"
                                    placeholder="Password"
                                    icon="error"
                                    value={values.password}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                  />
                                  <label
                                    className="form-label "
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.password && touched.password ? (
                                      <p className="v">{errors.password}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                            </div>
                            <button type="submit" className="button" id="but">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </section>
    </>
  );
}
