import React, { useState } from "react";
import { FaBars } from "react-icons/fa";
import {  NavLink } from "react-router-dom";
import "./SideBar.css";
import { BsPerson } from "react-icons/bs";
import { AiFillDashboard } from "react-icons/ai";
import { FaBoxOpen } from "react-icons/fa";
import { MdOutlineRemoveShoppingCart } from "react-icons/md";
import { FaIdeal } from "react-icons/fa";
import { MdPassword } from "react-icons/md";

import { CgProfile } from "react-icons/cg";

export default function SideBar() {
  const key = JSON.parse(localStorage.getItem("user-info"));
  const id = key.prank*2861999
 
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const menuItem = [
    {
      path: "/Dashboard",
      name: "Dashboard",
      icon: <AiFillDashboard />,
    },
    {
      path: "/product",
      name: "Product",
      icon: <FaBoxOpen />,
    },
    {
      path: "/userProfile",
      name: "Profile",
      icon: <CgProfile />,
    },
    {
      path: "/deal",
      name: "Deal",
      icon: <FaIdeal />,
    },
    
    {
      path: "/zeroP",
      name: "Out Of Stock",
      icon: <MdOutlineRemoveShoppingCart />,
    },
  
   
    {
      path: "/changePassword/"+id,
      name: "Change Password",
      icon: <MdPassword />,
    },
    
  ];
  const owners = [
    {
      path: "/Dashboard",
      name: "Dashboard",
      icon: <AiFillDashboard />,
    },
    {
      path: "/product",
      name: "Product",
      icon: <FaBoxOpen />,
    },
    {
      path: "/userProfile",
      name: "Profile",
      icon: <CgProfile />,
    },
    {
      path: "/deal",
      name: "Deal",
      icon: <FaIdeal />,
    },
   
    {
      path: "/zeroP",
      name: "Out Of Stock",
      icon: <MdOutlineRemoveShoppingCart />,
    },
  
    {
      path: "/User",
      name: "User",
      icon: <BsPerson />,
    },
    {
      path: "/changePassword/"+id,
      name: "Change Password",
      icon: <MdPassword />,
    },
   
  ];

  return (
    <>
      <div className="container1">
        <div className="sidebar " style={{ width: isOpen ? "300px" : "50px" }}>
          <div className="top_section mt-2">
          
            <div
              className="bars "
              style={{marginLeft: isOpen ? "13rem" : "15px" }}
            >
              <FaBars onClick={toggle} />
            </div>
          </div>
        <div style={{display :"flex", justifyContent:"center"}}>
        <img
            className="logo-nav"
            style={{ display: isOpen ? "block" : "none" , "width":"4rem", height:"4rem", "borderRadius":"100px", border:"1px solid white","boxShadow":"2px 2px 2px 0 white"}}
            src={"https://api.dealsmart.ae/Products/" + key.img}
            alt=""
          />
      
        </div>
        <div style={{display :"flex", justifyContent:"center"}}>
        <div
            className="logo-nav mt-2 mb-2"
            style={{ display: isOpen ? "block" : "none" , border:"1px solid white","boxShadow":"2px 2px 2px 0 white"}}
            
     
          >{key.name}</div>
        
        </div>
       {(key.actor === "owner")? <div>
          {owners.map((item, index) => (

            <NavLink
              to={item.path}
              key={index}
              className="link-nav
                "
              activeclassname="active"
            >
              <div className="icon"> {item.icon}</div>
              <div
                className="link_text"
                style={{ display: isOpen ? "block" : "none" }}
              >
                {item.name}
              </div>
            </NavLink>
          ))}
          </div>:
          
          <div>
          {menuItem.map((item, index) => (

            <NavLink
              to={item.path}
              key={index}
              className="link-nav
                "
              activeclassname="active"
            >
              <div className="icon"> {item.icon}</div>
              <div
                className="link_text"
                style={{ display: isOpen ? "block" : "none" }}
              >
                {item.name}
              </div>
            </NavLink>
          ))}
          </div>
          }
       
        
        </div>
      </div>
    </>
  );
}
