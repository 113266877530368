import React, { useEffect } from "react";
import http from '../../../http';
import { login } from "../../../schemas/login";
import { useFormik } from "formik";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
const initialValues = {
 

  email: "",
 
  password: "",
};
export default function Login() {
  const navigate = useNavigate();
  
 

  useEffect(() => {
    if (localStorage.getItem("user-info")) {
      navigate("/Dashboard");
    }else{

    }
  }, [navigate]);

  const { values, errors, handleBlur, handleChange, touched, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: login,
      onSubmit: (values, action) => {
        http.post("/login", values).then((res) => {
        
        const ide = res.data.prank/2861999;
        
        let info = {
          prank: ide,
          name: res.data.name,
          actor: res.data.actor,
          img: res.data.img,
          

        };
          localStorage.setItem("user-info", JSON.stringify(info));
   
          swal({
            title: "success!",
            text: "Succrssfully Login!",
            icon: "success",
            width: '247px',
            height: '314px',
            button: "OK",
          });
          navigate("/Dashboard");
        }).catch((error)=>{
          swal({
            title: "Fail!",
            text: "Wrong Email or Password",
            icon: "error",
            width: '250px',
            height: '314px',
            button: "OK",
          });
          action.resetForm();
        });
       
      },
    });

  return (
    <>

      <section className="intro">
       
        <div className=" mb-5">
          <section className="h-100 ">
          <form onSubmit={handleSubmit}>
            <div className="container py-5 h-100">
              <div className="row d-flex justify-content-center align-items-center h-100">
                <div className="col">
                  <div className="card card-registration my-4">
                    <div className="row g-0">
                      <div className="col-xl-5 d-none d-xl-block">
                        <img
                          src="https://images.unsplash.com/photo-1567502401218-0aa88642ea13?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=464&q=80"
                          alt=""
                          className="img-fluid"
                          style={{
                            borderTopLeftRadius: ".25rem",
                            borderBottomLeftRadius: ".25rem",
                            backgroundPosition: "center",
                            height:"22rem"
                          }}
                        />
                      </div>
                      <div className="col-xl-7 ">
                        <div className="card-body p-md-2 text-black">
                          <div id="grad">
                            <h3
                              className="flex logo "
                              id="grad"
                              style={{ justifyContent: "center" }}
                            >
                     Login
                            </h3>
                          </div>

                       
              
                    
                              <div className="form-outline">
                                <input
                                  type="text"
                                  id="form3Example1m"
                                  className="form-control form-control-lg f"
                                  name="email"
                                  placeholder="Email"
                                  value={values.email}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                />
                                 <label
                                  className="form-label"
                                  htmlFor="form3Example1m"
                                >
                                  {errors.email && touched.email ? (
                        <p className="v">{errors.email}</p>
                      ) : null}
                                </label>
                                
                           
                       
                          </div>
                          <div className="form-outline mb-2">
                            <input
                              type="password"
                              id="form3Example8"
                              className="form-control form-control-lg f"
                              name="password"
                              placeholder="Password"
                                  value={values.password}
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                            />
                             <label
                                  className="form-label"
                                  htmlFor="form3Example1m"
                                >
                                  {errors.password && touched.password ? (
                        <p className="v">{errors.password}</p>
                      ) : null}
                                </label>
                          </div>
                          <div className="row mb-4">
                        <div className="col flex">
                          <Link to="/ForgetEmail">Forgot password?</Link>
                          <Link to="/SignUp" style={{marginLeft:"1rem"}}>Create new Account?</Link>
                        </div>
                      </div>
                          <button type="submit" className="button" id="but">
                            SignUp
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            </form>
          </section>
        </div>
      </section>
    </>
  );
}
