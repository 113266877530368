import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
export default function AProtected(props) {
  const navigate = useNavigate();
  let Cmp = props.Cmp;

  useEffect(() => {
    if (!(localStorage.getItem("user-info"))) {
      navigate("/");
    }else{

    }
  }, [navigate]);
  return (
    <div>
      <Cmp />
    </div>
  );
}
