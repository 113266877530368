import React from "react";
// import { product } from "../../../schemas/product";
import { useFormik } from "formik";
import swal from "sweetalert";
import { useNavigate, useParams } from "react-router-dom";
const initialValues = {
  pID:"",
  img: "",
};
export default function AddImg() {
    const { id } = useParams();
  const navigate = useNavigate();
  const {
  
    setFieldValue,
    errors,
    handleBlur,
  
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    // validationSchema: product,

    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("pID", id);
      formData.append("img", values.img);


      fetch("http://api.dealsmart.ae/api/addImg/"+id, {
        method: "POST",
        body: formData,
      }).then((response) => {
        if (response.status === 200 || response.status === 201) {
          swal({
            title: "success!",
            text: "Succrssfully Upload!",
            icon: "success",
            width: '247px',
            height: '314px',
            button: "OK",
          });
          navigate("/product");
        } else {
          swal({
            title: "Fails!",
            text: "Upload Fails!",
            icon: "error",
            width: '247px',
            height: '314px',
            button: "OK",
          });
          navigate("/addImag/"+id);
        }
      });
    },
  });

  return (
    <>
      <section className="intro">
        <div className=" mb-5">
          <section className="h-100 ">
            <form onSubmit={handleSubmit}>
              <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col">
                    <div className="card card-registration my-4">
                      <div className="row g-0">
                        
                        <div className="col-xl-12 ">
                          <div className="card-body p-md-2 text-black">
                            <div id="grad">
                              <h3
                                className="flex logo "
                                id="grad"
                                style={{ justifyContent: "center" }}
                              >
                               ADD Product
                              </h3>
                            </div>

                          
                          
                             


                          
                            <div className="row">
                            
                              <div className="col-md-12 mb-1">
                              
                           
                                <div className="form-outline">
                                  <input
                                    type="file"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="img"
                                    accept=".jpg,.jpeg"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "img",
                                        event.target.files[0]
                                      );
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.img && touched.img ? (
                                      <p className="v">{errors.img}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                               
                              </div>
                            </div>
                            <button type="submit" className="button" id="but">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </section>
    </>
  );
}
