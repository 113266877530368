import React from "react";
import { product } from "../../../schemas/product";
import { useFormik } from "formik";
import swal from "sweetalert";
import { useNavigate } from "react-router-dom";
const initialValues = {
  name: "",
  total:"",
  offf:"",
  afterOff:"",
  stock:"",
  smallDesc:"",
  Des:'',
  cID:"",
  rank:"",
  img: "",
};
export default function AddProduct() {
  const navigate = useNavigate();
  const {
    values,
    setFieldValue,
    errors,
    handleBlur,
    handleChange,
    touched,
    handleSubmit,
  } = useFormik({
    initialValues: initialValues,
    validationSchema: product,

    onSubmit: (values) => {
      const formData = new FormData();
      formData.append("name", values.name);
      formData.append("total", values.total);
      formData.append("offf", values.offf);
      formData.append("afterOff", values.afterOff);
      formData.append("stock", values.stock);
      formData.append("cID", values.cID);
      formData.append("rank", values.rank);
      formData.append("smallDesc", values.smallDesc);
      formData.append("Des", values.Des);
      formData.append("img", values.img);


      fetch("https://api.dealsmart.ae/api/addProduct", {
        method: "POST",
        body: formData,
      }).then((response) => {
        if (response.status === 200 || response.status === 201) {
          swal({
            title: "success!",
            text: "Succrssfully Add!",
            icon: "success",
            width: '247px',
            height: '314px',
            button: "OK",
          });
          navigate("/product");
        } else {
          swal({
            title: "Fails!",
            text: "Fails to Add!",
            icon: "error",
            width: '247px',
            height: '314px',
            button: "OK",
          });
          navigate("/addProduct");
        }
      });
    },
  });

  return (
    <>
      <section className="intro">
        <div className=" mb-5">
          <section className="h-100 ">
            <form onSubmit={handleSubmit}>
              <div className="container py-5 h-100">
                <div className="row d-flex justify-content-center align-items-center h-100">
                  <div className="col">
                    <div className="card card-registration my-4">
                      <div className="row g-0">
                        
                        <div className="col-xl-12 ">
                          <div className="card-body p-md-2 text-black">
                            <div id="grad">
                              <h3
                                className="flex logo "
                                id="grad"
                                style={{ justifyContent: "center" }}
                              >
                               ADD Product
                              </h3>
                            </div>

                            <div className="row">
                              <div className="col-md-6 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="text"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="name"
                                    value={values.name}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Full Name"
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.name && touched.name ? (
                                      <p className="v">{errors.name}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                              <div className="form-outline">
                                  <input
                                    type="number"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="total"
                                    value={values.total}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Orignal price"
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.total && touched.total ? (
                                      <p className="v">{errors.total}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                            </div>
                            

                            <div className="row">
                              <div className="col-md-6 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="number"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="offf"
                                    value={values.offf}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter off"
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.offf && touched.offf ? (
                                      <p className="v">{errors.offf}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                              <div className="form-outline">
                                  <input
                                    type="number"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="afterOff"
                                    value={values.afterOff}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder={(values.total - (values.offf *(values.total/100)))}
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.afterOff && touched.afterOff ? (
                                      <p className="v">{errors.afterOff}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                            </div>


                            <div className="row">
                              <div className="col-md-6 mb-1">
                                <div className="form-outline">
                                  <input
                                    type="number"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="stock"
                                    value={values.stock}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Products in Stock"
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.stock && touched.stock ? (
                                      <p className="v">{errors.stock}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                              <div className="form-outline">
                                  <select
                                    type="number"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="cID"
                                    value={values.cID}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    
                                  >
                                    <option value="0" style={{'fontSize':"11px"}}>Select Category</option>
                                    <option value="1" style={{'fontSize':"11px"}}>Watches</option>
                                    <option value="2" style={{'fontSize':"11px"}}>Cosmetics</option>
                                    <option value="3" style={{'fontSize':"11px"}}>Hair</option>
                                    <option value="4" style={{'fontSize':"11px"}}>Kitchen</option>
                                    <option value="5" style={{'fontSize':"11px"}}>Mobile</option>
                                    <option value="6" style={{'fontSize':"11px"}}>Car</option>
                                    <option value="7" style={{'fontSize':"11px"}}>Electronics</option>
                                    <option value="8" style={{'fontSize':"11px"}}>Other</option>
                                  </select>
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.cID && touched.cID ? (
                                      <p className="v">{errors.cID}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                            </div>
                          
                            <div className="row">
                            <div className="col-md-6 mb-1">
                              <div className="form-outline">
                                  <input
                                    type="number"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="rank"
                                    value={values.rank}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Enter Ranking 1-3"
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.rank && touched.rank ? (
                                      <p className="v">{errors.rank}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                              
                           
                                <div className="form-outline">
                                  <input
                                    type="file"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="img"
                                    accept=".jpg,.jpeg"
                                    onChange={(event) => {
                                      setFieldValue(
                                        "img",
                                        event.target.files[0]
                                      );
                                    }}
                                    onBlur={handleBlur}
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.img && touched.img ? (
                                      <p className="v">{errors.img}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                               
                              </div>
                            </div>


                            <div className="row">
                              <div className="col-md-6 mb-1">
                                <div className="form-outline">
                                  <textarea
                                    type="number"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="smallDesc"
                                    value={values.smallDesc}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Overview"
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.smallDesc && touched.smallDesc ? (
                                      <p className="v">{errors.smallDesc}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                              <div className="col-md-6 mb-1">
                              <div className="form-outline">
                                  <textarea
                                    type="text"
                                    id="form3Example1m"
                                    className="form-control form-control-lg f"
                                    name="Des"
                                    value={values.Des}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Detailed Description"
                                  />
                                  <label
                                    className="form-label"
                                    htmlFor="form3Example1m"
                                  >
                                    {errors.Des && touched.Des ? (
                                      <p className="v">{errors.Des}</p>
                                    ) : null}
                                  </label>
                                </div>
                              </div>
                            </div>


                            <button type="submit" className="button mb-5" id="but">
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </div>
      </section>
    </>
  );
}
