import React, { useState, useEffect } from 'react';
import http from '../../../http';

function BillD() {
  const [deals, setDeals] = useState([]);

  useEffect(() => {
    fetchAllData();
  }, []);
  const fetchAllData = () => {
    http.get("/allBillPrint").then((res) => {
      setDeals(res.data);
      console.log(res.data)
    });
  };


  return (
    <div>
    {deals.map((deal) => (
        <div key={deal.id}>
          <h2>{deal.id}</h2>
            <h2>{deal.name}</h2>
            <p>{deal.pno}</p>
            <p>{deal.emi}</p>
            <p>{deal.address}</p>
      
            {/* <ul>
                {deal.orders.map((order) => (
                    <li key={order.pID}>
                        <p>{order.qun}</p>
                        <p>{order.pID}</p>
                    </li>
                ))}
            </ul> */}
        </div>
    ))}
</div>
);
}


export default BillD;