import React, { useEffect, useState , useRef } from 'react';
import {  useParams } from 'react-router-dom'
import http from '../../../http';
import {useReactToPrint} from 'react-to-print';


export default function DealDetail() {
    const [data, setDatas] = useState([]);
    const { id } = useParams();
    useEffect(() => {
      const fetchAllData = () => {
        http.get("/showByIdDeal/" + id).then((res) => {
          setDatas(res.data);
        });
      };
      fetchAllData();
    }, [id]);


  const [datas, setData] = useState([]);
  var totalbill = 0;
  useEffect(() => {
    fetchAllData();
  }, []);
  const fetchAllData = () => {
    
    http.get("/showByIdOrder/"+id).then((res) => {
      setData(res.data);
      
    });
  };
  
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
      content: () => componentRef.current,
      documentTitle: 'emp-data',
      onAfterPrint: ()=> alert('print success')
  });
  return (
    <>
        <div className="mt-2 mb-2" style={{display:"flex",justifyContent:"center"}}>
          
          <button type="submit" className="button" onClick={handlePrint} >
            Print
          </button>
       
      </div>
     <div className='container flex' style={{"justifyContent":"center"}} >
 
     <div className='container shad mt-3 opts ' style={{background:"white", "height":"auto"}} >
      <form  ref={componentRef}>
  <div className="form-group ">

  <label htmlFor="exampleInputEmail1" className='opt mt-2 ' >Deal Confirmed!</label><br/>
  <label htmlFor="exampleInputEmail1" className='opt mt-3' >Customer:  {data.name}</label><br/>
  
  </div>
 
  <div className='cont'>


  </div>
  <div className='cont container' >  <table className="table">
  <thead>
    <tr>
      <th scope="col" className='opt'>Order Date<br/>
      {data.date}</th>
      <th scope="col" className='opt'>Order No<br/>{data.id}</th>
      
      <th scope="col" className='opt'>Shiping Address<br/>{data.emi },{ data.address}</th>
    </tr>
  </thead>
  <tbody>
  {datas.map((item, index) => {
     totalbill += item.qun * item.afterOff;
    return(
   
    <tr>
      <th scope="row"><img src={"https://api.dealsmart.ae/Products/" + item.img} alt="" style={{width:"4rem"}}></img></th>
      <td  className='opt'>{item.smallDesc} <br/>
      Quantity: {item.qun}</td>
      <td className='opt'>{ item.qun * item.afterOff} AED
     
        
      </td>

    </tr>
   )})}
  </tbody>
</table></div>
<div style={{width:"15rem", float:"right", "marginLeft":"-5rem"}} className="mt-3">
<table className="table">
  <thead>
    <tr>
      <th scope="col" className='opt'>Subtotal
      </th>
      <th scope="col" className='opt'>{totalbill} AED </th>
      
    </tr>
  </thead>
  <tbody>
    <tr>
      
      <td className='opt'>Shipping Fee</td>
      <td className='opt'>Free</td>
    </tr>
    <tr>
      
      <td className='opt'>Grand Total</td>
      <td className='opt'>{totalbill} AED</td>
    </tr>

    <div><span className='opt'>Payment Cash on Delivery</span></div>

    <div><span className='opt'>Thanks for shopping with us!</span>

   </div>
    
  </tbody>
</table>
</div>
</form>
    </div>
   
     </div>
    
    </>
  )
}
